import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    Web: "Website",
    Repo: "Repository",
    // PHOTOS: "Photos",
  };

  const projectsData = [
     {
      title: "Tidy Bird",
      projectInfo: "Currently in the beta phase of creating a web application called Tidy Bird, located in Toronto, Ontario. Tidy Bird operates as a ghost kitchen, offering delicious burgers, crispy chicken wings, and golden fries.",
      // 
      technologies: "PERN Stack",
      industry: "F&B",
      date: "2023",
      url: {
        name: "Tidy Bird",
        link: "https://tidybird.ca",
      },

      thumbImage: "images/projects/tidyBird.png",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      // ],
      categories: [filters.Web],
    },
    {
      title: "Watson's Toronto",
      projectInfo: "This web application is dedicated to a cocktail bar located in downtown Toronto, known for its exceptional selection of spirits and delightful cocktails. In accordance with the owner's request, this website is designed to lead users to a reservation page, enticing curiosity and encouraging more bookings.",

      technologies: "Express JS, Node JS, Handlebars JS, MongoDB",
      industry: "F&B",
      date: "2021",
      url: {
        name: "Watson's Toronto",
        link: "https://watsonstoronto.com",
      },

      thumbImage: "images/projects/project-2.jpg",

      categories: [filters.Web],
    },
    {
      title: "Tres Compadres",
      projectInfo: "My inaugural project, as requested by a client, involved creating a Mexican restaurant with a ghost kitchen concept.",

      technologies: "Express JS, Node JS, Handlebars JS, MongoDB",
      industry: "F&B",
      date: "2020",
      url: {
        name: "Tres Compadres",
        link: "https://www.trescompadresto.com",
      },

      thumbImage: "images/projects/project-3.jpg",

      categories: [filters.Web],
    },
    {
      title: "Open AI",
      projectInfo: "This application is meticulously designed to leverage the potential of AI through the OpenAI API, offering users the opportunity to explore and practice with cutting-edge AI models.",
      // 
      technologies: "React Native, Ionic Framework, Typescript, Node JS, Express JS",
      industry: "Tech",
      date: "2022",
      url: {
        name: "Open AI Api",
        link: "https://opentestai.netlify.app",
      },

      thumbImage: "images/projects/project-1.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      // ],
      categories: [filters.Web],
    },
    {
      title: "Admin Dashboard",
      projectInfo: "Admin Dashboard for Menu Updates at Watson's Toronto.",

      technologies: "Node JS, Express JS, MongoDB",
      industry: "F&B",
      date: "2021",
      url: {
        name: "Admin Dashboard",
        link: "http://watsons-dashboard.herokuapp.com/login",
      },

      thumbImage: "images/projects/project-4.jpg",

      categories: [filters.Web],
    },
    {
      title: "Resume Genie",
      projectInfo: "This app empowers users to generate resumes by entering their information into a form. While it also has the capability to compose cover letters, this feature is currently on hold for enhancements in performance and language.",

      technologies: "PERN Stack",
      industry: "Tech",
      date: "2022",
      url: {
        name: "Resume Genie",
        link: "https://smonajemi.netlify.app",
      },

      thumbImage: "images/projects/project-5.png",

      categories: [filters.Web],
    },
    {
      title: "Calculator",
      projectInfo: "A React practice application designed to replicate the functionality of the Apple calculator.",

      technologies: "PERN Stack",
      industry: "Tech",
      date: "2023",
      url: {
        name: "Calculator",
        link: "https://tester-calculator.netlify.app",
      },

      thumbImage: "images/projects/project-6.png",

      categories: [filters.Web],
    },
    {
      title: "Profile",
      projectInfo: "",

      technologies: "PERN Stack",
      industry: "Tech",
      date: "2022",
      url: {
        name: "Profile",
        link: "https://monajemi.netlify.app",
      },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/project-7.png",

      categories: [filters.Web],
    },
    {
      title: "Backend Structure",
      projectInfo: "The backend has been developed as the initial stage for future practice and serves as a foundational structure for creating PERN stack applications.",

      technologies: "PERN Stack",
      industry: "Tech",
      date: "2021",
      url: {
        name: "Git Repo",
        link: "https://github.com/smonajemi/knex-practice",
      },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/git-repo.png",

      categories: [filters.Repo],
    },
    {
      title: "Landing Page",
      projectInfo: "The landing page serves as the initial phase in the development of future enhancements.",

      technologies: "PERN Stack",
      industry: "Tech",
      date: "2022",
      url: {
        name: "Landing Page",
        link: "https://landing-page-comingsoon.netlify.app",
      },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/project-10.jpg",

      categories: [filters.Web],
    },
    {
      title: "Peyto Tech",
      projectInfo: "An MVP (Minimum Viable Product) developed for a tech company, which, although it did not achieve success, was retained for ongoing practice and learning purposes.",

      technologies: "MERN Stack",
      industry: "Tech",
      date: "2020",
      url: {
        name: "Peyto Tech",
        link: "https://peytotech.ca",
      },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/project-8.jpg",

      categories: [filters.Web],
    },
    {
      title: "Brick Breaker",
      projectInfo: "A Java game created to enhance my Java skills during the early stages of my learning journey.",

      technologies: "Java",
      industry: "Gaming",
      date: "2019",
      url: {
        name: "Git Repo",
        link: "https://github.com/smonajemi/Java/tree/main/BrickBreaker",
      },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/git-repo.png",

      categories: [filters.Repo],
    },
    {
      title: "Coding Challenge",
      projectInfo: "A coding challenge undertaken during my initial role at BPTN, where I selected favorite movies and added them to a list, all before delving into React.",

      technologies: "React, JavaScript",
      industry: "Tech",
      date: "2021",
      url: {
        name: "Git Repo",
        link: "https://github.com/smonajemi/CodeChallenge",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/git-repo.png",

      categories: [filters.Repo],
    },
    {
      title: "Mortgage Calculator",
      projectInfo: "A recent project aimed at honing React state management skills—simple yet valuable for learning purposes.",

      technologies: "PERN Stack",
      industry: "Tech",
      date: "2023",
      url: {
        name: "Mortgage Calculator",
        link: "https://smon-mortgagecalculator.netlify.app",
      },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/project-9.png",

      categories: [filters.Web],
    },



  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              My Work
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            <span className="text-light">Category</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
