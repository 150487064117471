import React, {useState} from "react";
import resumeFile from "../documents/smonajemi.pdf";

const Resume = ({ classicHeader, darkTheme }) => {
  const [filterKey, setFilterKey] = useState("Education");
  const filters = {
    Edu: "Education",
    Exp: "Experience",
    // PHOTOS: "Photos",
  };
  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const educationDetails = [
    {
      yearRange: "2023 - 2023",
      title: "Cybersecurity Analyst",
      place: "Bootcamp - MasterSchool",
      categories: [filters.Edu],
      // desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    },
    {
      yearRange: "2018 - 2021",
      title: "Software Programming",
      place: "Seneca College",
       categories: [filters.Edu],
      // desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    },
    {
      yearRange: "2013 - 2014",
      title: "Hotel and Restaurant Management",
      place: "Seneca College",
      categories: [filters.Edu],
      // desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    },
    {
      yearRange: "2012",
      title: "French Immersion",
      place: "University of Alberta",
      categories: [filters.Edu],
      // desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    },
    {
      yearRange: "2011",
      title: "French Immersion",
      place: "Université de Montréal",
      categories: [filters.Edu],
      // desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    },
    {
      yearRange: "2009 - 2011",
      title: "High School",
      place: "Holy Heart of Mary High School",
      categories: [filters.Edu],
      // desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "2022 - 2023",
      title: "Frontend Developer",
      place: "Tehran, Ir",
      desc: "While working remotely with a group of people, I took the initiative to implement responsive user interfaces that ensured compatibility across various devices and browsers. Additionally, I significantly enhanced the product catalog with features for seamless browsing and filtering, in addition to integrating robust e-commerce functionalities such as shopping cart management, secure payment processing, and order tracking. I am highly proficient in web application development using React, Tailwind CSS, and Ant Design, and have been an invaluable asset to my team in contributing to the overall success of our projects.",
      categories: [filters.Exp],
    },
    {
      yearRange: "2020 - 2023",
      title: "Full Stack Developer",
      place: "Toronto, ON",
      desc: "My proficiency extends to crafting robust web applications using both the MERN (MongoDB, Express.js, React, Node.js) and PERN (PostgreSQL, Express.js, React, Node.js) stacks. I also possess a solid foundation in SQL and NoSQL query languages. Currently, I leverage the OpenAI API to create engaging cross-platform mobile applications using the Ionic framework. Additionally, I have a track record of improving website design for better navigation and aesthetics, along with providing valuable consultancy services for businesses seeking website development assistance.",
      categories: [filters.Exp],
    },
    {
      yearRange: "2021 - 2022",
      title: "Junior Software Developer",
      place: "Toronto, ON",
      desc: " Actively engaged in daily scrum meetings, spring planning, and bug fixes while collaborating closely with QA, product teams, and fellow developers to ensure the timely and high-quality development of the BPTN social networking platform, Obsidi.com, using the PERN stack. Furthermore, I enhanced website performance by refactoring legacy, unutilized code to adhere to best practices and built a REST API using PostgreSQL with Knex Js.",
      categories: [filters.Exp],

    },
    {
      yearRange: "2020",
      title: "Software Developer",
      place: "Toronto, ON",
      desc: "During my first experience, I collaborated efficiently with team members and a project manager using Slack communication to create a remote application for digital receipts and expense payments during the COVID-19 Business Response Hackathon. I actively participated in code reviews to uphold high code quality standards and contributed innovative ideas for developing a contact-free application to promote community safety. Additionally, I analyzed user requirements and suggested software design solutions to enhance the user interface and overall user experience, implementing improvements that boosted web functionality and responsiveness.",
      categories: [filters.Exp],
    },
    {
      yearRange: "2017 - 2020",
      title: "Night Auditor/Front Desk Agent",
      place: "Toronto, ON",
      desc: " I maintained a high level of professionalism while engaging with guests to deliver an outstanding customer service experience. I fostered long-term relationships with guests, fostering loyalty and repeat visits. In addition to this, I played a pivotal role in task prioritization and organization, ensuring an efficient workflow. Furthermore, I consistently demonstrated exceptional decision-making and leadership skills, especially when confronted with challenging situations.",
      categories: [filters.Exp],
    },
  ];
  const filteredDetails =
    filterKey === filters.Edu
      ? educationDetails
      : filterKey === filters.Exp
      ? experienceDetails
      : [...educationDetails, ...experienceDetails];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        <ul
          className={
            "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
            (darkTheme ? "nav-light" : "")
          }
        >
          {Object.keys(filters).map((oneKey, i) => (
            <li className="nav-item" key={i}>
              <button
                className={
                  "nav-link " + (filterKey === filters[oneKey] ? "active" : "")
                }
                onClick={handleFilterKeyChange(filters[oneKey])}
              >
                {filters[oneKey]}
              </button>
            </li>
          ))}
        </ul>
        <div className="row gx-5">
          {filteredDetails.map((value, index) => (
            <div
              key={index}
              className={
                "col-md-6 " +
                (filterKey === filters.Edu ? "education" : "experience")
              }
            >
              <div
                className={
                  "bg-white  rounded p-4 mb-4 " +
                  (darkTheme ? "bg-dark" : "bg-white border")
                }
              >
                <p className="badge bg-primary text-2 fw-400">
                  {value.yearRange}
                </p>
                <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                  {value.title}
                </h3>
                <p className={darkTheme ? "text-primary" : "text-danger"}>
                  {value.place}
                </p>
                <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                  {value.desc}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Resume;
