import React from "react";

const Services = ({ classicHeader, darkTheme }) => {
  // services details
  const services = [
    {
      name: "Web Application Developer",
      desc: "I demonstrate expertise in web application development using both PERN (PostgreSQL, Express.js, React, Node.js) and MERN (MongoDB, Express.js, React, Node.js) stacks, ensuring seamless integration and optimal functionality across these technologies.",
      icon: "fas fa-palette",
    },
    {
      name: "User-Centric Design Enthusiast",
      desc: "I leverage a profound understanding of user requirements to consistently propose innovative design strategies that enhance both UI and UX.",
      icon: "fas fa-pencil-ruler",
    },
    {
      name: "Design and Architecture",
      desc: "Once I grasp the project requirements, I begin with system design, focusing on the overall architecture. I carefully choose appropriate algorithms, data structures, and design patterns. My approach includes both high-level designs, which outline the broader system interactions, and detailed designs that specify interactions between software components.",
      icon: "fas fa-desktop",
    },
    {
      name: "Maintenance and Optimization",
      desc: "My duties encompass resolving software bugs, boosting performance, and adding features in response to feedback or emerging requirements. This often involves code refactoring, optimizing algorithms, or integrating new systems and technologies.",
      icon: "fas fa-toolbox",
    }



  ];

  return (
    <section
      id="services"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")
            }
          >
            Services
          </h2>
          <p
            className={
              "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            What I Do?
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* content start */}
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              {services.length > 0 &&
                services.map((service, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="featured-box style-3 mb-5">
                      <div
                        className={
                          "featured-box-icon text-primary  shadow-sm rounded " +
                          (darkTheme ? "bg-dark-1" : "bg-white")
                        }
                      >
                        <i className={service.icon} />
                      </div>
                      <h3 className={darkTheme ? "text-white" : ""}>
                        {service.name}
                      </h3>
                      <p
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                      >
                        {service.desc}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* content end */}
      </div>
    </section>
  );
};

export default Services;
