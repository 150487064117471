import "./App.scss";
import Header from "./components/Header";
import Home from "./components/Home";
import AboutUs from "./components/About";
import Services from "./components/Services";
import Resume from "./components/Resume";
import Portfolio from "./components/Portfolio";
import Testimonials from "./components/Testimonials";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import { useEffect, useState } from "react";
import ClassicHeader from "./components/ClassicHeader";
import { commonConfig } from "./config/commonConfig";
import TermsAndConditions from "./components/TermsAndConditions";
import Disclaimer from "./components/Disclaimer";
import PreLoader from "./components/Preloader";
import { Tooltip } from "./components/Tooltip";
import { useLocalStorage } from './hooks/useLocalStorage';
import PlayMusic from '../src/components/PayMusic'
import Sound from "react-sound";
import musicFile from './documents/smonajemi.mp3'
import { Box } from "@mui/material";


function App() {
  const classicHeader = commonConfig.classicHeader;
  const darkTheme = commonConfig.darkTheme;
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const [isThemeEnabled, setThemeEnabled] = useState(localStorage.getItem('darkTheme') === 'true');

  const handleMusicToggle = () => {
    setIsMusicPlaying(!isMusicPlaying);
  };


  const handleNavClick = (section) => {
    document.getElementById(section).scrollIntoView({ behavior: "smooth" });
  };

  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => {
      clearTimeout(loadingTimeout);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('darkTheme', isThemeEnabled.toString());
    
  }, [isThemeEnabled]);

  const handleThemeToggle = async () => {
   await setThemeEnabled((prevThemeState) => !prevThemeState); 
    // localStorage.setItem('darkTheme', !darkTheme);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  

  return (
    <>
      <Box
        style={{ position: "relative" }}
        className={classicHeader ? "" : "side-header"}
      >
        {isLoading && <PreLoader></PreLoader>}

        <Box id="main-wrapper">
          {classicHeader ? (
            <ClassicHeader handleNavClick={handleNavClick}></ClassicHeader>
          ) : (
            <Header handleNavClick={handleNavClick}></Header>
          )}

          <Box id="content" role="main">
            <Home
              classicHeader={classicHeader}
              darkTheme={darkTheme}
              handleNavClick={handleNavClick}
            ></Home>
            <AboutUs
              classicHeader={classicHeader}
              darkTheme={darkTheme}
            ></AboutUs>
            <Services
              classicHeader={classicHeader}
              darkTheme={darkTheme}
            ></Services>
            <Resume
              classicHeader={classicHeader}
              darkTheme={darkTheme}
            ></Resume>
            <Portfolio
              classicHeader={classicHeader}
              darkTheme={darkTheme}
            ></Portfolio>
            <Contact
              classicHeader={classicHeader}
              darkTheme={darkTheme}
            ></Contact>
          </Box>
          <Footer
            classicHeader={classicHeader}
            darkTheme={darkTheme}
            handleNavClick={() => { }}
            isMusicPlaying={isMusicPlaying}
            handleMusicToggle={handleMusicToggle}
            handleThemeToggle={handleThemeToggle}
            isThemeEnabled={isThemeEnabled}
          />
          <PlayMusic
            url={musicFile}
            playStatus={isMusicPlaying ? Sound.status.PLAYING : Sound.status.PAUSED}
          />

        </Box>
        {/* back to top */}
        <Tooltip text="Back to Top" placement="left">
          <span
            id="back-to-top"
            className="rounded-circle"
            style={{ display: scrollTopVisible ? "inline" : "none" }}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <i className="fa fa-chevron-up"></i>
          </span>
        </Tooltip>

        <TermsAndConditions darkTheme={darkTheme}></TermsAndConditions>
        <Disclaimer darkTheme={darkTheme}></Disclaimer>
      </Box>
    </>
  );
}

export default App;
