import React from "react";
import resumeFile from "../documents/smonajemi.pdf";
import InterviewVideo from "./InterviewVideo";
const AboutUs = ({ classicHeader, darkTheme }) => {
  const handleLinkClick = () => {
    window.open('https://www.linkedin.com/in/sinamonajemi/', '_blank');
  };
  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            About Me
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Know Me More
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              I'm <span className="text-primary">Sina Monajemi,</span> a Software
              Developer
            </h2>
            {/* <InterviewVideo /> */}

            <p className={darkTheme ? "text-white-50" : ""}>
              An experienced professional with a strong passion for technology and a proven track record of exceptional interpersonal and communication skills. My career journey began in the hospitality sector, where I cultivated teamwork and collaboration abilities while taking on diverse responsibilities.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              However, my unwavering passion for the dynamic challenges and growth potential within the IT field eventually led me to transition.
              As a proactive and motivated individual, I enthusiastically embraced freelance projects, placing a high value on learning and constant enhancement of my skills. I frequently undertook unpaid assignments to expand my knowledge base and refine my abilities. In addition to my academic pursuits, I have actively honed my skills through consistent practice on platforms like LeetCode. I believe in the power of hands-on learning and regularly challenge myself with coding problems to enhance my problem-solving abilities.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              My time in Agile environments has equipped me with the confidence and expertise needed to collaborate effectively with team members, consistently delivering outstanding results. This journey has honed my ability to be transparent and an active listener, qualities that I now bring to the world of software development, where I have accumulated three years of valuable experience.
            </p>
          </div>
          {/* About me content end */}
          {/* about me personal detials start */}
          <div className="col-lg-5 col-xl-4">
            <div className="ps-lg-4">
              <ul
                className={
                  "list-style-2 " +
                  (darkTheme ? "list-style-light text-light" : "")
                }
              >
                <li>
                  <span className="fw-600 me-2">LinkedIn:</span>
                  <a
                    href="https://www.linkedin.com/in/sinamonajemi/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleLinkClick}
                  >
                    Sina Monajemi
                  </a>
                </li>
                <li>
                  <span className="fw-600 me-2">Email:</span>
                  <a href="mailto:sina.monajemi@me.com">sina.monajemi@me.com</a>
                </li>
                <li className="border-0">
                  <span className="fw-600 me-2">From:</span>Toronto,
                  Ontario
                </li>
              </ul>
              <a
                href={resumeFile}
                download
                className="btn btn-primary rounded-pill"
              >
                Download CV
              </a>
              
              
            </div>
          </div>
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>3</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Years Experience
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>2</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Happy Clients
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>10</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Projects Done
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>100%</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Enthusiasm
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
