import React from "react";
import {
  Box,
  FormGroup,
  Stack,
  Typography,
  Switch,
  Container,
  Grid,
  Paper,
} from "@mui/material";
import { useResponsiveness } from "../hooks/useResponsiveness";
import { PlayArrow, Pause, DarkMode, LightMode } from "@mui/icons-material";
const Footer = ({
  classicHeader,
  darkTheme,
  handleNavClick,
  isMusicPlaying,
  handleMusicToggle,
  isThemeEnabled,
  handleThemeToggle,
}) => {
  const { isDevice } = useResponsiveness();
  return (
    <Paper
      id="footer"
      elevation={0}
      className={"section " + (darkTheme ? "footer-dark bg-dark-1" : "")}
    >
      <Container className={"container " + (classicHeader ? "" : "px-lg-5")}>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" component="p" className="mb-3 mb-lg-0">
            Copyright © 2023{" "}
            <a
              href="#home"
              className="fw-500"
              onClick={(e) => {
                e.preventDefault();
                handleNavClick("home");
              }}
            >
              {process.env.REACT_APP_WEBSITE_NAME}
            </a>
            . All Rights Reserved.
          </Typography>
        </Box>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <FormGroup>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body1">
                {" "}
                {isMusicPlaying ? <Pause /> : <PlayArrow />}
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                style={{ fontSize: "12px", padding: "0px" }}
              >
                <Switch
                  checked={isMusicPlaying}
                  onChange={handleMusicToggle}
                  inputProps={{ "aria-label": "ant design" }}
                  style={{ transform: "scale(0.8)" }}
                />
              </Stack>
            </Box>
          </FormGroup>

          <FormGroup>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body1">
                {isThemeEnabled ? <LightMode /> : <DarkMode />}
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                style={{ fontSize: "12px", padding: "0px" }}
              >
                <Switch
                  checked={!isThemeEnabled}
                  onChange={handleThemeToggle}
                  inputProps={{ "aria-label": "ant design" }}
                  style={{ transform: "scale(0.8)" }}
                />
              </Stack>
            </Box>
          </FormGroup>
        </Box>
      </Container>
    </Paper>
  );
};

export default Footer;
