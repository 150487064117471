import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

export const useApi = (darkTheme) => {
  const [loading, setLoading] = useState(false);

  // Function to call the API
  const callEmailApi = async (apiName, firstName, lastName, email, message) => {
    try {
      setLoading(true);
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_SECRET_KEY,
      };

      const requestBody = { firstName, lastName, email, message };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/${apiName}`,
        requestBody,
        { headers }
      );

      if (response.status === 200) {
        toast.success("Message sent successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: darkTheme ? "dark" : "light",
        });
      } else {
        toast.error("Something went wrong!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: darkTheme ? "dark" : "light",
        });
      }

      return response;
    } catch (error) {
      toast.error("Something went wrong!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: darkTheme ? "dark" : "light",
      });
      return null;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    callEmailApi,
  };
};
